import styled, { createGlobalStyle } from 'styled-components';

const Background = createGlobalStyle`
  html {
    background-color: #E4EBF5;
  }
`
const BridgeWrapper = styled.div`
  position: relative;
  display: grid;
  max-width: 1234px;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  gap: 12px;
  padding: 200px 20px 100px 20px;
  background-color: #E4EBF5;
`;

const BridgeBody = styled.div`
  width: 600px;
  @media (max-width: 700px) {
    width: 400px;
  }
  @media (max-width: 450px) {
    box-shadow: none;
    border: 0;
  }
  /* height: 600px; */
  padding: 20px;
  /* background: rgba(255, 255, 255, 0.6); */
  /* border: 0.5px solid rgba(255, 255, 255, 0.6); */
  box-sizing: border-box;
  backdrop-filter: blur(40px);
  /* border-radius: 20px; */
  background-color: #E4EBF5;

  border-radius: 3rem;
  /* box-shadow:.8rem .8rem 1.4rem #c8d0e7, -.2rem -.2rem 1.8rem #ffffff; */
`


const Title = styled.h1`
  margin: 20px 0 0 0
`
const Description = styled.p`
  margin: 0 0 40px 0  
`

const AssetBox = styled.div`
  margin: 20px 20px;
  overflow: hidden;
  box-shadow: .3rem .3rem .6rem #c8d0e7, -.2rem -.2rem .5rem #ffffff;
  border-radius: 2.0rem;
  display: grid;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  grid-template-columns: 40% minmax(0, 1fr);
  gap: 10px;
  text-align: left;
  padding: 20px 30px;
  align-items: center; 

`
const ChainContainer = styled.div`
  margin: 20px 20px;
  display: grid;
  grid-template-columns: 50% minmax(0, 1fr);
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  gap: 10px;
  text-align: left;
  align-items: center; 
`
const ChainBox = styled.div`
  overflow: hidden;
  display: grid;
  gap: 10px;
  box-shadow: .3rem .3rem .6rem #c8d0e7, -.2rem -.2rem .5rem #ffffff;
  border-radius: 3.0rem;
  padding: 20px 30px;
  border-radius: 2.0rem;
`

const ChainButton = styled.div`
    color: #46525C;
    width: 186px;
    height: 40px;
    @media (max-width: 700px) {
      width: 100%;
    }
    align-content: center;
    padding: 0 0 0 10px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.55);    
    display: grid;
    grid-template-columns: 0.1fr 0.8fr 0.1fr;
    align-items: center; 
    gap: 10px;
    cursor:pointer;
`

const ChainText = styled.p``
const ChainSymbol = styled.img`
  height: 25px;
`

const ButtonSymbol = styled.p`
  
`
const Form = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 30%;
  align-items: center; 
  /* overflow: hidden; */
`
const Input = styled.input`
  /* width: 20.4rem; */
  height: 40px;
  margin: 0 10px 0px 0;
  border: none;
  border-radius: 1rem;
  font-size: 2rem;
  /* padding-left: 1.4rem; */
  background: none;
  font-family: inherit;
  font-weight: 500;
  color: rgb(5, 21, 36);
  text-align: right;
  appearance: none;

  @media (max-width: 700px) {
    /* outline: none; box-shadow: .3rem .3rem .6rem #c8d0e7, -.2rem -.2rem .5rem #ffffff; */
    /* box-shadow: rgba(255, 255, 255, 0.5) -3px -3px 6px inset, rgba(174, 174, 192, 0.16) 3px 3px 6px inset; */
    padding: 0 10px 0px 0;
    width: 186px;
    border: solid;
    border-color: #8E959D;
    border-width: 2px;
    border-radius: 20px;
  }
  
  /* &::placeholder { color: #8E959D; } */
  /* &:focus { outline: none; box-shadow: .3rem .3rem .6rem #c8d0e7, -.2rem -.2rem .5rem #ffffff; } */
`
const TokenName = styled.p`
  /* background-color: lightblue; */
  /* height: 40px; */
  margin: 0;
  text-align: left;
  @media (max-width: 700px) {
    text-align: right;
  }
  font-size: 1.4rem;
  font-weight: 500;
`

const BoxHeading = styled.h5`
  /* background-color: lightblue; */
  margin: 0;
`

const TransferButton = styled.div`
  width: 186px;
  height: 40px;
  margin: 0 auto;
  /* padding: 2rem; */
  overflow: hidden;
  display: grid;
  justify-content: center;
  text-align: center;
  align-items: center;
  box-shadow: .3rem .3rem .6rem #c8d0e7, -.2rem -.2rem .5rem #ffffff;
  border-radius: 20px;
  cursor: pointer;
  background-image: -moz-linear-gradient( 29deg, rgb(255,148,147) 0%, rgb(255,120,162) 100% );
  background-image: -webkit-linear-gradient( 29deg, rgb(255,148,147) 0%, rgb(255,120,162) 100% );
  background-image: -ms-linear-gradient( 29deg, rgb(255,148,147) 0%, rgb(255,120,162) 100% );
`

const TransferText = styled.p`
  margin: 0;
  color: white;
  font-weight: 500;
`

/* The Modal (background) */
const Modal = styled.div`
  position: fixed;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index: 9999;

`

/* Modal Content/Box */
const ModalContent = styled.div`
  background-color: #E4EBF5;
  /* margin: 15% auto;  */
  /* @media (max-width: 700px) {
    margin: 25% auto;
  } */
  padding: 30px;
  border: 1px solid #888;
  width: 300px; /* Could be more or less, depending on screen size */
  border-radius: 40px;
  display: grid;
  gap: 10px;  
  position: relative;
  justify-content: center;
  top: 25%;
  left: calc(50% - 150px);
`
/* The Close Button */
const CloseModal = styled.span`
  position: absolute;
  color: #aaa;
  top: 10px;
  right: 30px;
  font-size: 28px;
  font-weight: bold;

  :hover, :focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`

export { Background, Modal, ModalContent, CloseModal,  BridgeWrapper, BridgeBody, Title, Description, ChainBox, AssetBox, BoxHeading, ChainContainer, TransferButton, Form, Input, ButtonSymbol, ChainButton, ChainText, ChainSymbol, TokenName, TransferText};
