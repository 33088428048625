import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/theme/crypto';
import { ResetCSS } from 'common/assets/css/style';
import { GlobalStyle, BridgeWrapper } from 'containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Crypto/NavbarApp';
import Seo from 'components/seo';
import Bridge from '../containers/Crypto/BridgeSection';

const App = () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Fragment>
        <Seo title="Batchy" />
        <ResetCSS />
        <GlobalStyle />
        <BridgeWrapper>
          <Sticky top={0} innerZ={9998} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <Bridge />

        </BridgeWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default App;
