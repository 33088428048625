import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {    Background, BridgeWrapper, BridgeBody, Title, Description, ChainBox, AssetBox, BoxHeading,  ChainContainer, 
            ChainButton,ChainText, ChainSymbol, TransferButton, Form, Input, TransferText, Modal, ModalContent, CloseModal, 
            ButtonSymbol, TokenName 
        } from './bridgeSection.style';

import { ethers } from 'ethers';
import { tokens, networks } from '../../../common/data/Crypto';
import { UserContext } from '../../../context/UserContext';


const ModalType = {
    asset: 'asset',
    currentChain: 'currentChain',
    destinationChain: 'destinationChain',
};

const BridgeSection = () => {

    const [showModal, setShowModal] = useState({show: false, type: null});
    const [showFinalTxn, setShowFinalTxn] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(tokens.DAI);
    const [currentChain, setCurrentChain] = useState(networks.Ethereum);
    const [destinationChain, setDestinationChain] = useState(networks.Polygon);
    const [bridgeAmount, setBridgeAmount] = useState("");
    const [ethersProvider, setEthersProvider] = useState(undefined);
    const { wallet } = useContext(UserContext)

    useEffect(() => {

        
        if (window.ethereum !== undefined) {
            if (window.ethereum.networkVersion === networks.Ethereum.Id) {
                console.log("Ethereum")
                showModal.type = ModalType.currentChain
                updateSelectedChain( networks.Ethereum )
            } else if (window.ethereum.networkVersion === networks.Polygon.Id) {
                console.log("Polygon")
                showModal.type = ModalType.currentChain
                updateSelectedChain( networks.Polygon )
            }
            
            let provider = new ethers.providers.Web3Provider(window.ethereum);
            setEthersProvider(provider)
        }

    }, []);

    // useEffect(() => {
    //     console.log("Updating Txn Cost")
    //     if (wallet.network) {
    //         getTransactionCost()
    //     }
        

    // }, [ currentChain, wallet ] );

    // async function getTransactionCost() {
    //     let TOKEN = new ethers.Contract(
    //         selectedAsset.address[wallet.network.Id],
    //         erc20ABI,
    //         ethersProvider.getSigner()
    //     );
    //     let gasCost = await TOKEN.estimateGas.transfer(currentChain.contractAddress, 1)
    //     let gasPrice = await ethersProvider.getGasPrice()
    //     let etherPrice = currentChain.Id === networks.Ethereum.Id ? 3122 : 1

    //     let transactionFee = gasCost.mul(gasPrice)
    //     let feeUSD = ethers.utils.formatEther(transactionFee.mul(etherPrice))
    //     setTransactionCost(feeUSD)
    // }

    function updateSelectedAsset( token ) {
        setSelectedAsset(token); 
        setShowModal({show: false, type: null});
    }

    let erc20ABI = [ 
        "function transfer(address to, uint amount)",
    ]; 
    // const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);

    async function transferToken() {
        try {
            if (bridgeAmount === "") {
                alert(`Please enter a valid amount of ${selectedAsset.ticker} to transfer`)
                return
            }
            let amnt = ethers.utils.parseUnits(bridgeAmount, selectedAsset.decimals)
            if (wallet.network.Id === currentChain.Id && amnt.gt(0)) {
                let TOKEN = new ethers.Contract(
                    selectedAsset.address[wallet.network.Id],
                    erc20ABI,
                    ethersProvider.getSigner()
                );
                
                let gasCost = await TOKEN.estimateGas.transfer(currentChain.contractAddress, amnt)
                let txn = await TOKEN.transfer(currentChain.contractAddress, amnt, { gasLimit: gasCost.add(5000) })
                console.log(txn);
                console.log("Loading ...")
                const reciept = await txn.wait()
                console.log("Done! Here is your reciept: ", reciept)

                
            } else if (wallet.network.Id !== currentChain.Id) {
                alert(`Please change to the ${currentChain.name} network to complete this transaction.`)
            } else {
                console.log("No network selected")
            }
        }
        catch {
            console.log("Transfer Failed")
        }
        
    }

    function updateSelectedChain( network ) {
        if (showModal.type === ModalType.currentChain) {
            setCurrentChain(network)
            if (network.name === networks.Ethereum.name) {
                setDestinationChain(networks.Polygon)
            } else {
                setDestinationChain(networks.Ethereum)
            }
        } else if (showModal.type === ModalType.destinationChain) {
            setDestinationChain(network)
            if (network.name === networks.Ethereum.name) {
                setCurrentChain(networks.Polygon)
            } else {
                setCurrentChain(networks.Ethereum)
            }
        }
        
        setShowModal({show: false, type: null});
    }

    function validateInput(value) {
      const re = /^[.0-9\b]+$/;

      if (value === '' || re.test(value)) {
         setBridgeAmount(value)
      }
    }

    return (
        <BridgeWrapper>
            <Background/>
            <BridgeBody>
                <Title> Batchy Bridge </Title>
                <Description> Transfer your crypto from one chain to another. </Description>
                    <AssetBox>
                        <BoxHeading> Asset </BoxHeading>
                        <BoxHeading /> 
                        <ChainButton onClick={() => setShowModal({show: true, type: ModalType.asset})} > 
                            <ChainSymbol src={selectedAsset.image} />
                            <ChainText> {selectedAsset.name} </ChainText>
                            <ButtonSymbol> 
                                <svg width="15" height="10" viewBox="-2.5 -5 75 60" preserveAspectRatio="none">
                                    <path d="M0,0 l35,50 l35,-50" fill="none" stroke="black" strokeLinecap="round" strokeWidth="10" />
                                </svg>
                            </ButtonSymbol>
                        </ChainButton>
                        <Form>
                            <Input value={bridgeAmount} onChange={e => validateInput(e.target.value)} placeholder="0.0"></Input>
                            <TokenName> {selectedAsset.ticker } </TokenName>
                        </Form>
                    </AssetBox>
                <ChainContainer>
                    <ChainBox>
                        <BoxHeading> From </BoxHeading>
                        <ChainButton onClick={() => setShowModal({show: true, type: ModalType.currentChain})} > 
                            <ChainSymbol src={currentChain.image} />
                            <ChainText> { currentChain.name } </ChainText>
                            <ButtonSymbol> 
                                <svg width="15" height="10" viewBox="-2.5 -5 75 60" preserveAspectRatio="none">
                                    <path d="M0,0 l35,50 l35,-50" fill="none" stroke="black" strokeLinecap="round" strokeWidth="10" />
                                </svg>
                            </ButtonSymbol>
                        </ChainButton>
                    </ChainBox>
                    <ChainBox>
                        <BoxHeading> To </BoxHeading>
                        <ChainButton onClick={() => setShowModal({show: true, type: ModalType.destinationChain})}> 
                            <ChainSymbol src={destinationChain.image} />
                            <ChainText> { destinationChain.name } </ChainText>
                            <ButtonSymbol> 
                                <svg width="15" height="10" viewBox="-2.5 -5 75 60" preserveAspectRatio="none">
                                    <path d="M0,0 l35,50 l35,-50" fill="none" stroke="black" strokeLinecap="round" strokeWidth="10" />
                                </svg>
                            </ButtonSymbol>
                        </ChainButton>
                    </ChainBox>
                </ChainContainer>

                {/* <DetailWrapper>
                    <DetailTitle>Details</DetailTitle>
                    <DetailSub style={{display: showFinalTxn ? "block" : "none"}}>  You will receive exactly {bridgeAmount} {selectedAsset.ticker} on {destinationChain.name} within 24hrs for a fee of ~$3 USD.</DetailSub>
                </DetailWrapper> */}
                <TransferButton onClick={ () => transferToken()}> 
                {/* <TransferButton onClick={ () => setShowFinalTxn(true)}>  */}
                    <TransferText> Transfer </TransferText>    
                </TransferButton>

                {/* {
                    bridgeAmount !== "" && 
                    <DetailWrapper>
                        <DetailItem> Amount Received: { bridgeAmount } { selectedAsset.ticker }  </DetailItem>
                    </DetailWrapper>
                } */}


            </BridgeBody>
            <Modal style={{display: showModal.show ? "block" : "none"}}>
                <ModalContent>
                    <CloseModal onClick={() => setShowModal(false)}>&times;</CloseModal>
                    <p> {showModal.type === ModalType.asset ? "Select A Token" : "Select A Blockchain"}</p>
                    {
                        showModal.type === ModalType.asset ?

                            Object.keys(tokens).map(( key, index ) => 
                                <ChainButton key={tokens[key].name} onClick={() => updateSelectedAsset(tokens[key])} > 
                                    <ChainSymbol src={tokens[key].image} />
                                    <ChainText> {tokens[key].name} </ChainText>
                                </ChainButton>
                            )

                            :

                            Object.keys(networks).map(( key, index ) => 
                                <ChainButton key={networks[key].name} onClick={() => updateSelectedChain(networks[key])} > 
                                    <ChainSymbol src={networks[key].image} />
                                    <ChainText> {networks[key].name} </ChainText>
                                </ChainButton>
                            )
                    }
                </ModalContent>
            </Modal>
            {/* <Modal style={{display: showFinalTxn ? "block" : "none"}}>
                    <FinalTxnContent>
                        <CloseModal onClick={() => setShowFinalTxn(false)}>&times;</CloseModal>
                        <TxnTitle> Confirm Transfer </TxnTitle>
                        <TxnSub> Please review your transaction</TxnSub>
                        <TxnSymbol src={selectedAsset.image} />
                        <TxnAmount> {bridgeAmount } {selectedAsset.ticker} </TxnAmount>
                        <TxnChainWrapper>
                            <ChainButton style={{width: "100%"}}> 
                                <ChainSymbol src={currentChain.image} />
                                <ChainText> {currentChain.name} </ChainText>
                            </ChainButton>
                            <ButtonSymbol style={{margin: "5px 0"}}> 
                                <svg width="15" height="10" viewBox="-2.5 -5 75 60" preserveAspectRatio="none">
                                    <path d="M0,0 l35,50 l35,-50" fill="none" stroke="black" strokeLinecap="round" strokeWidth="10" />
                                </svg>
                            </ButtonSymbol>
                            <ChainButton style={{width: "100%"}}> 
                                <ChainSymbol src={destinationChain.image} />
                                <ChainText> {destinationChain.name} </ChainText>
                            </ChainButton>
                        </TxnChainWrapper>
                        <TxnDescription> You will receive exactly { bridgeAmount } { selectedAsset.ticker } on { destinationChain.name } within 24 hrs. </TxnDescription>
                        <TransferButton onClick={ () => transferToken()}> 
                            <TransferText> Transfer </TransferText>    
                        </TransferButton>
                    </FinalTxnContent>
            </Modal> */}
        </BridgeWrapper>
        
    )
}

const DetailWrapper = styled.div`
    overflow: hidden;
    text-align: left;
    display: grid;
    gap: 10px;
    padding: 0px 30px 0px 30px;
    margin: 20px 0 0 0;
`
const DetailItem = styled.p`
    color: gray;
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
`
const DetailTitle = styled.h5`
    margin: 5px 0 5px 0;
    text-align: left;
`
const DetailSub = styled.p`
    margin: 0 0 0 0;
    text-align: left;

`
/* Modal Content/Box */
const TxnTitle = styled.h4`
    margin: 20px 0 0 0;
` 
const TxnSub = styled.p`
    margin: 0
` 
const TxnSymbol = styled.img`
  height: 40px;
  margin: 20px auto 0 auto;
`
const TxnAmount = styled.h2`
    margin: 10px 0 0 0px;
` 
const TxnChainWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0
    /* gap: 10px; */
`
const TxnDescription = styled.p`
    font-size: 12px;
    width: 240px;
`
const FinalTxnContent = styled.div`
  background-color: #E4EBF5;
  padding: 30px;
  border: 1px solid #888;
  width: 460px;
  @media (max-width: 500px) {
    width: 300px;
    left: calc(50% - 150px);
    top: 10%;
  }
  border-radius: 40px;
  display: grid;
  gap: 0px;  
  position: relative;
  justify-content: center;
  /* display: grid;
  align-items: center;
  text-align: center;  */
  top: 25%;
  left: calc(50% - 230px);

`

export default BridgeSection;
