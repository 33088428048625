import styled from 'styled-components';

const AccountButton = styled.div`
    color: #ffffff;
    width: 186px;

    height: 40px;
    align-content: center;
    padding: 0 0 0 10px;
    border-radius: 20px;
    background-color: #028489;    
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
    align-items: center; 
    /* gap: 10px; */
    overflow: hidden;
    background-image: -moz-linear-gradient( 29deg, rgb(255,148,147) 0%, rgb(255,120,162) 100% );
    background-image: -webkit-linear-gradient( 29deg, rgb(255,148,147) 0%, rgb(255,120,162) 100% );
    background-image: -ms-linear-gradient( 29deg, rgb(255,148,147) 0%, rgb(255,120,162) 100% );
    cursor:pointer;
    /* box-shadow: .3rem .3rem .6rem #c8d0e7, -.2rem -.2rem .5rem #ffffff; */

`

const AccountText = styled.p`
  margin: 0;
  font-weight: 500;
`

export { AccountButton, AccountText }