import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';
import Button from 'common/components/Button';
import { AccountButton, AccountText } from '../../../common/components/AccountButton';
import Logo from 'common/components/UIElements/Logo';
import Box from 'common/components/Box';
import HamburgMenu from 'common/components/HamburgMenu';
import Container from 'common/components/UI/Container';
import { DrawerContext } from 'common/contexts/DrawerContext';
import { UserContext } from '../../../context/UserContext';

import LogoImage from 'common/assets/image/Crypto/logo-white.png';
import LogoImageAlt from 'common/assets/image/Crypto/logo.png';
import AccountImg from 'common/assets/image/crypto/account.inline.svg'
import { ChainButton, ChainSymbol, ChainText } from '../BridgeSection/bridgeSection.style';
import { networks } from '../../../common/data/Crypto';

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const { state, dispatch } = useContext(DrawerContext);
  const { wallet, setWallet } = useContext(UserContext)


  // const [selectedAddress, setSelectedAddress] = useState(undefined);
  // const [networkError, setNetworkError] = useState(false)
  const [network, setNetwork] = useState(undefined)

  useEffect(() => {

    if (window.ethereum !== undefined) {
      connectWallet()

      window.ethereum.on("accountsChanged", ([newAddress]) => {
        window.location.reload();
      });
      // When user changes their network.
      window.ethereum.on("chainChanged", ([newChainId]) => {  
          window.location.reload();
      });
    }

  }, []);

  async function connectWallet() {

    try {
      // const [address] = await window.ethereum.enable();
      const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
      // check the network
      if (!checkNetwork()) {
        return false;
      }
  
      const short = address.substring(0, 7) + "..." + address.slice(-4)

      let net = undefined
      if (window.ethereum.networkVersion === networks.Ethereum.Id) {   
        net = networks.Ethereum
        setNetwork(net)
      } else if (window.ethereum.networkVersion === networks.Polygon.Id) {
        net = networks.Polygon
        setNetwork(net)
      } 

      setWallet({ isConnected: true, address: address, shortAddress : short, network: net })
  
      console.log("Your address is: ", address)
    } catch {
      console.log("Unable to connect to wallet")
    }

  }

  function checkNetwork() {
    // check the network
    if (window.ethereum.networkVersion === networks.Ethereum.Id) {   
      console.log( "Connected to Goerli ", window.ethereum.networkVersion )
      return true;
    } else if (window.ethereum.networkVersion === networks.Polygon.Id) {
      console.log( "Connected to Mumbai ", window.ethereum.networkVersion )
      return true;
    } 
    else {
      console.log("Wrong Network: ", window.ethereum.networkVersion)
      // setNetworkError(true)
      alert("Wrong Network: Please connect to Goerli or Mumbai.");
      return false
    }
  }

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Portfolio"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/"
            logoSrc={LogoImageAlt}
            title="Portfolio"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>

            <Link className="navbar_button" to="/app">
              { !wallet.isConnected ?
                (
                  <Button onClick={() => connectWallet() } {...button} title="Connect Wallet" />
                )
                :
                (
                  <ButtonWrapper>
                    <ChainButton style={{cursor: "default"}}> 
                      <ChainSymbol src={ network.image } />
                      <ChainText> { network.name  } </ChainText>
                    </ChainButton>
                    <AccountButton> 
                      <AccountImg/>
                      <AccountText> {wallet.shortAddress} </AccountText> 
                    </AccountButton>
                  </ButtonWrapper>
                )
                
              }

            </Link>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >

              <Link className="navbar_drawer_button" to="/app">
              { !wallet.isConnected ?
                (
                  <Button onClick={() => connectWallet() } {...button} title="Connect Wallet" />
                )
                :
                (
                  <AccountButton> 
                    <AccountImg/>
                    <AccountText> {wallet.shortAddress} </AccountText> 
                  </AccountButton>
                )
                
              }
              </Link>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

const ButtonWrapper = styled.div`
  position: relative;
  display: grid;
  margin: 0 auto;
  justify-content: center;
  /* text-align: center; */
  gap: 12px;
  grid-template-columns: 0.5fr 0.5fr;
  /* padding: 200px 20px 100px 20px; */
  /* background-color: #E4EBF5; */
`;
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
